import { create, useStore } from 'zustand';

/* Store used to share data about the current  payment donation of user  */

interface State {
  paymentUrl: string;
  preOrderId: string | number;
}

interface Actions {
  setState: (state: State) => void;
}

interface Context {
  state: State;
  actions: Actions;
}

export const GlobalStore = create<Context>((set) => ({
  state: {
    paymentUrl: '',
    preOrderId: '',
  },
  actions: {
    setState: (newState: State) => set((state) => ({ state: newState })),
  },
}));

export function usePaymentStore() {
  return useStore(GlobalStore);
}
