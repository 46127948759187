/* eslint-disable max-len */
import React from 'react';

interface Props {
  active: boolean;
}
export const Report = ({ active }: Props) => {
  if (active)
    return (
      <svg
        width="22"
        height="22"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.33268 10.6668H10.666V12.0002H5.33268V10.6668ZM5.33268 8.00016H10.666V9.3335H5.33268V8.00016ZM9.33268 1.3335H3.99935C3.26602 1.3335 2.66602 1.9335 2.66602 2.66683V13.3335C2.66602 14.0668 3.25935 14.6668 3.99268 14.6668H11.9993C12.7327 14.6668 13.3327 14.0668 13.3327 13.3335V5.3335L9.33268 1.3335ZM11.9993 13.3335H3.99935V2.66683H8.66602V6.00016H11.9993V13.3335Z"
          fill="#fff"
        />
      </svg>
    );

  return (
    <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.33268 10.6668H10.666V12.0002H5.33268V10.6668ZM5.33268 8.00016H10.666V9.3335H5.33268V8.00016ZM9.33268 1.3335H3.99935C3.26602 1.3335 2.66602 1.9335 2.66602 2.66683V13.3335C2.66602 14.0668 3.25935 14.6668 3.99268 14.6668H11.9993C12.7327 14.6668 13.3327 14.0668 13.3327 13.3335V5.3335L9.33268 1.3335ZM11.9993 13.3335H3.99935V2.66683H8.66602V6.00016H11.9993V13.3335Z"
        fill="#1485C8"
      />
    </svg>
  );
};
