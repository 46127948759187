import axios from 'axios';

type CepMetadata = {
  bairro: 'Sylvio Pereira II';
  cep: '35171-369';
  localidade: 'Coronel Fabriciano'; // city
  logradouro: 'Rua Vinte'; // street
  uf: 'MG'; // state
};
type ApiResponse = CepMetadata | { erro: true };

interface ErrorCase {
  error: true;
  message: string;
}

interface SuccessCase {
  error: false;
  data: CepMetadata;
}

type OutPut = ErrorCase | SuccessCase;

const fetchAddressFromCep = async (cep: string): Promise<OutPut> => {
  try {
    const response = await axios.get<ApiResponse>(`https://viacep.com.br/ws/${cep}/json/`);
    if ('erro' in response.data) {
      return { error: true, message: '' };
    } else {
      return { error: false, data: response.data };
    }
  } catch (error) {
    return { error: true, message: 'Nao foi possível consultar o CEP informado!' };
  }
};

export default fetchAddressFromCep;
