import { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  children: ReactNode;
}

export const Title = (p: Props) => {
  return <TitleCss>{p.children}</TitleCss>;
};

const TitleCss = styled.h1`
  font-weight: 600;
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.fontSize.x};
  line-height: 24px;
`;
