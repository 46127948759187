import { HttpClient } from '../../HttpClient';

export enum OrderStatusEnum {
  'PENDENTE' = 'PENDENTE',
  'CANCELADO' = 'CANCELADO',
  'SUCESSO' = 'SUCESSO',
}

interface GetStatusOfLastPaymentOrderOfUserResponse {
  data: { status: OrderStatusEnum };
}

export async function GetStatusOfLastPaymentOrderOfUser(preOrderId: number | string) {
  const response = await HttpClient<void, GetStatusOfLastPaymentOrderOfUserResponse>(
    'get',
    '/orders/last/' + preOrderId
  );
  return response;
}
