import { useRef, MouseEvent, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Header, Nav, Lines } from './styles';
import { NavIconLink } from '../NavIconLink/NavIconLink';
import { Heart } from '../../../assets/icons/Heart';
import { Glogo } from '../../../assets/icons/Glogo';
import { Logoff } from '../../../assets/icons/Logoff';
import { Dashboard } from '../../../assets/icons/Dashboard';
import { handleLogout } from '../../../../application/handlers/HandleLogout';
import logo_blue from '../../../assets/logo/logo_blue.svg';
import IonIcon from '@reacticons/ionicons';
import { useGlobalStore } from '../../../../infra/state/GlobalStore';
import { Home } from '../../../assets/icons/Home';
import { Wallet } from '../../../assets/icons/Wallet';
import { AclSelection } from './components/AclSelection';
import { List } from '../../../assets/icons/List';
import { ROUTES_PATH } from '../../../../infra/config/routes';
import { UserTypeEnum } from '../../../../domain/interfaces/User';
import { Report } from '../../../assets/icons/Report';
import { Discount } from '../../../assets/icons/Discount';
import { User } from '../../../assets/icons/User';
import { Hash } from '../../../assets/icons/Hash';
import { Receipt } from '../../../assets/icons/Receipt';

interface Props {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface SidebarItem {
  name: string;
  path: string;
  icon: React.ReactNode;
  dataTest: string;
}

export const Sidebar = ({ openModal, setOpenModal }: Props) => {
  const sidebar = useRef<HTMLDivElement>(null);
  const store = useGlobalStore().state;
  const { state } = useGlobalStore();
  const { pathname } = useLocation();
  const [mapRendered, setMapRendered] = useState(false);
  const [items, setItems] = useState<SidebarItem[]>([]);

  const Mobile: SidebarItem[] = [
    {
      name: 'Home',
      path: '/home',
      dataTest: 'navIconLink-home',
      icon: <Home active={pathname.includes('/home')} />,
    },
    {
      name: 'Campanhas',
      path: ROUTES_PATH.listCampaignsForUsers,
      dataTest: 'navIconLink-Campanhas',
      icon: <Heart active={pathname.includes(ROUTES_PATH.listCampaignsForUsers)} />,
    },
    // {
    //   name: 'Recompensas',
    //   path: '/recompensas',
    //   dataTest: 'navIconLink-recompensas',
    //   icon: <Wallet active={pathname.includes('/recompensas')} />,
    // },
    {
      name: 'Profile',
      path: '/profile',
      dataTest: 'navIconLink-Profile',
      icon: <User active={pathname.includes('/profile')} />,
    },
  ];

  const userOrg: SidebarItem[] = [
    {
      name: 'Dashboard',
      path: '/dashboard',
      dataTest: 'navIconLink-DashboardOrg',
      icon: <Dashboard active={pathname.includes('/dashboard')} />,
    },
    {
      name: 'Gerenciar Campanhas',
      path: ROUTES_PATH.manageCampaign,
      dataTest: 'navIconLink-GerenciarCampanha',
      icon: <List active={pathname.includes(ROUTES_PATH.manageCampaign)} />,
    },
    {
      name: 'Gerenciar Usuários',
      path: '/gerenciar-usuarios',
      dataTest: 'navIconLink-GerenciarUsuarios',
      icon: <User active={pathname.includes('/gerenciar-usuarios')} />,
    },
    {
      name: 'Relatórios',
      path: '/report',
      dataTest: 'navIconLink-userOrgReport',
      icon: <Report active={pathname.includes('/report')} />,
    },
  ];

  const userInst: SidebarItem[] = [
    {
      name: 'Dashboard',
      path: '/dashboard',
      dataTest: 'navIconLink-DashboardInst',
      icon: <Dashboard active={pathname.includes('/dashboard')} />,
    },
    {
      name: 'Gerenciar Campanhas',
      path: ROUTES_PATH.manageCampaign,
      dataTest: 'navIconLink-GerenciarCampanha',
      icon: <List active={pathname.includes(ROUTES_PATH.manageCampaign)} />,
    },
    {
      name: 'Gerenciar Usuários',
      path: '/gerenciar-usuarios',
      dataTest: 'navIconLink-GerenciarUsuarios',
      icon: <User active={pathname.includes('/gerenciar-usuarios')} />,
    },
    {
      name: 'Gerenciar Recibo',
      path: '/gerenciar-recibo',
      dataTest: 'navIconLink-GerenciarRecibo',
      icon: <Receipt active={pathname.includes('/gerenciar-recibo')} />,
    },
    {
      name: 'Relatórios',
      path: '/report',
      dataTest: 'navIconLink-userOrgReport',
      icon: <Report active={pathname.includes('/report')} />,
    },
  ];

  const userGoodersMember: SidebarItem[] = [
    {
      name: 'Recompensas',
      path: '/recompensas',
      dataTest: 'navIconLink-recompensas',
      icon: <Wallet active={pathname.includes('/recompensas')} />,
    },
    {
      name: 'Dashboard',
      path: '/dashboard',
      dataTest: 'navIconLink-userGoodersMember',
      icon: <Dashboard active={pathname.includes('/dashboard')} />,
    },
    // {
    //   name: 'Relatórios',
    //   path: '/report',
    //   dataTest: 'navIconLink-userGoodersMemberReport',
    //   icon: <Report active={pathname.includes('/report')} />,
    // },
    {
      name: 'Gerenciar Campanhas',
      path: ROUTES_PATH.manageCampaign,
      dataTest: 'navIconLink-GerenciarCampanha',
      icon: <List active={pathname.includes(ROUTES_PATH.manageCampaign)} />,
    },
    {
      name: 'Gerenciar Recompensas',
      path: '/gerenciar-recompensas',
      dataTest: 'navIconLink-userGoodersMemberReportRewards',
      icon: <Discount active={pathname.includes('/gerenciar-recompensas')} />,
    },
    {
      name: 'Gerenciar Usuários',
      path: '/gerenciar-usuarios',
      dataTest: 'navIconLink-GerenciarUsuarios',
      icon: <User active={pathname.includes('/gerenciar-usuarios')} />,
    },
    {
      name: 'Hash',
      path: '/hash',
      dataTest: 'navIconLink-Hash',
      icon: <Hash active={pathname.includes('/hash')} />,
    },
  ];

  useEffect(() => {
    const fixedRoutes = [
      {
        name: 'Home',
        path: '/home',
        dataTest: 'navIconLink-home',
        icon: <Home active={pathname.includes('/home')} />,
      },
      {
        name: 'Campanhas',
        path: ROUTES_PATH.listCampaignsForUsers,
        dataTest: 'navIconLink-Campanhas',
        icon: <Heart active={pathname.includes(ROUTES_PATH.listCampaignsForUsers)} />,
      },
      {
        name: 'Resgatar Hash',
        path: '/rescue_hash',
        dataTest: 'navIconLink-Hash',
        icon: <Hash active={pathname.includes('/rescue_hash')} />,
      },
    ];

    const checkACLAndUpdateSidebar = () => {
      if (state.acl.selectedACL?.type === UserTypeEnum.GOODERS) {
        return setItems([...fixedRoutes, ...userGoodersMember]);
      }
      if (state.acl.selectedACL !== null) {
        if (state.acl.selectedACL.type === UserTypeEnum.ORG) {
          setItems([...fixedRoutes, ...userOrg]);
        } else if (state.acl.selectedACL.type === UserTypeEnum.INSTITUTION) {
          setItems([...fixedRoutes, ...userInst]);
        }
      }
    };
    setItems(fixedRoutes);
    checkACLAndUpdateSidebar();
    setMapRendered(true);
  }, [state.acl.selectedACL]);

  function handleSidebarModal() {
    setOpenModal(!openModal);
  }

  function handleOutSideClickModal({ target }: MouseEvent<HTMLDivElement>) {
    if (!sidebar.current?.contains(target as Node)) {
      setOpenModal(!openModal);
    }
  }

  return (
    <Container.main openModal={openModal} onClick={handleOutSideClickModal}>
      <Container.sidebar openModal={openModal} ref={sidebar}>
        <nav>
          <ul className="ulMobileNone">
            <li>
              <Header.img>
                <img
                  data-test="avatar-user"
                  src={`/avatar/${state.user?.avatar}.svg`}
                  alt="AvatarUser"
                />
              </Header.img>
              <Header.title openModal={openModal}>
                <Header.name data-test="text-header-sidebar">
                  Olá, {store.user?.username}
                </Header.name>
                <Header.edit data-test="link-edit-profile">
                  <Link to="/profile" style={{ zIndex: 10 }}>
                    Editar Perfil
                  </Link>
                </Header.edit>
              </Header.title>
            </li>
            <div className="icon" style={{ cursor: 'pointer' }}>
              <IonIcon
                onClick={handleSidebarModal}
                size="large"
                data-test="icon-header-sidebar"
                name={openModal ? 'chevron-back-outline' : 'chevron-forward-outline'}
              />
            </div>

            <Container.acl data-test="container-acl-sidebar" openModal={openModal}>
              {/* usestate */}
              {(state.acl.goodersMember !== null ||
                state.user?.acls.orgs.length !== 0 ||
                state.user.acls.institutions.length !== 0) && <AclSelection />}
            </Container.acl>

            <Lines className="line" />
            {items.map((item, index) => (
              <li key={index} className="navIconLink" data-test={item.dataTest}>
                <NavIconLink
                  icon={item.icon}
                  redirectPath={item.path}
                  active={pathname.includes(item.path)}
                />
                <Link to={item.path}>
                  <Nav.text openModal={openModal}>{item.name}</Nav.text>
                </Link>
              </li>
            ))}
            <Lines className="line" />
            {mapRendered && (
              <div className="logout">
                <li
                  onClick={handleLogout}
                  data-test="Icon-logoff"
                  className="navIconLink"
                  style={{ cursor: 'pointer' }}
                >
                  <Logoff active={pathname.includes('/logoff')} />
                  <Nav.text data-test="navIconLink-logoff" openModal={openModal}>
                    Sair
                  </Nav.text>
                </li>
              </div>
            )}
          </ul>

          <ul>
            <section style={{ display: 'flex' }}>
              {Mobile.map((item, index) => (
                <li key={index} className="navIconMobile" data-test={item.dataTest}>
                  <NavIconLink
                    icon={item.icon}
                    redirectPath={item.path}
                    active={pathname.includes(item.path)}
                  />
                </li>
              ))}
            </section>
          </ul>

          <ul className="ulMobileNone">
            <Lines className="line" />
            <li>
              {!openModal && (
                <NavIconLink
                  icon={<Glogo data-test="navIconLink-glogo" active={pathname.includes('/#')} />}
                  redirectPath="/#"
                />
              )}
              <Nav.img openModal={openModal}>
                <img src={logo_blue} alt="logo" />
              </Nav.img>
            </li>
          </ul>
        </nav>
      </Container.sidebar>
    </Container.main>
  );
};
