import { HttpClient } from '../../HttpClient';

export async function CreateOrUpdateSigntureOfReceiptUseCase(
  dto: FormData,
  institutionId: number | string
) {
  const response = await HttpClient(
    'patch',
    `/users/institutions/receipt/signatures/institutions/${institutionId}`,
    dto,
    true
  );
  return response;
}
