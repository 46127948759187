import { HttpClient } from '../../HttpClient';

export interface CreatePrePaymentOrderDto {
  campaignId: number;
}

export interface CreatePrePaymentOrderResponse {
  data: {
    preOrderId: string;
  };
}

export async function CreatePrePaymentOrderUseCase(dto: CreatePrePaymentOrderDto) {
  const response = await HttpClient<undefined, CreatePrePaymentOrderResponse>(
    'post',
    `/api/checkout/pre-orders/${dto.campaignId}`
  );

  return response;
}
