import { Box } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LabelInputFormCampaign } from '../../../components/common/Input';
import { cnpj } from 'cpf-cnpj-validator';
import { useEffect, useState } from 'react';
import fetchAddressFromCep from '../../../../application/useCases/GetCepUseCase';
import { HttpClient } from '../../../../application/HttpClient';
import { Component, Container } from './styles';
import { Title } from '../../../components/common/Title';
import { SelectInput } from '../../../components/common/SelectInput';
import { selectOptionsState } from '../../../components/common/SelectInput/components/SelectOptionsState';
import { useGlobalStore } from '../../../../infra/state/GlobalStore';
import { BlueButton } from '../../../components/common/Button';
import { useAlert } from '../../../../infra/config/AlertContext/useAlert';
import { CreateOrUpdateSigntureOfReceiptUseCase } from '../../../../application/useCases/institution/CreateOrUpdateReceiptUseCase';
import { FindInstitutionReceiptUseCase } from '../../../../application/useCases/institution/FindInstitutionReceiptUseCase';

interface FullAddress {
  address: string;
  city: string;
  state: string;
  zipCode: string;
}

interface CreateOrUpdateReceiptDto {
  sender: string;
  cnpj: string;
  fullAddress: FullAddress;
  name: string;
}

export async function CreateOrUpdateReceiptData(dto: any, institutionId: number | string) {
  const response = await HttpClient(
    'post',
    `/users/institutions/receipt/institutions/${institutionId}`,
    dto
  );
  return response;
}

function sendNewImageUseCase(ev: React.ChangeEvent<HTMLInputElement>, institutionId: number) {
  const file = ev.target.files![0];
  const formData = new FormData();
  formData.append('image', file);
  CreateOrUpdateSigntureOfReceiptUseCase(formData, institutionId);
}

const validationSchema = Yup.object().shape({
  address: Yup.object().shape({
    city: Yup.string().required('A cidade é obrigatória'),
    state: Yup.string().required('O estado é obrigatório'),
    zipCode: Yup.string().required('O CEP é obrigatório'),
    number: Yup.string().required('O Numero é obrigatório'),
  }),
  sender: Yup.string().required('O nome do sender é obrigatório'),
  cnpj: Yup.string()
    .required('O CNPJ é obrigatório')
    .test('CPF', 'CNPJ inválido', (a) => {
      return cnpj.isValid(a);
    }),
  name: Yup.string().required('O nome é obrigatório'),
});
export const InstitutionGerenciarRecibo = () => {
  const { setAlert } = useAlert();
  const [signatureURL, setSignatureURL] = useState<null | string>();
  const {
    state: {
      acl: { selectedACL },
    },
  } = useGlobalStore();
  const formik = useFormik({
    initialValues: {
      address: {
        complement: '',
        neighborhood: '',
        road: '',
        city: '',
        state: '',
        number: '',
        zipCode: '',
      },
      sender: '',
      cnpj: '',
      name: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      values.cnpj = values.cnpj.replace(/[^0-9]/g, '');
      await CreateOrUpdateReceiptData(values, selectedACL!.id);
    },
  });

  function applyMaskOnCNPJ(ev: React.ChangeEvent<HTMLInputElement>) {
    if ('inputType' in ev.nativeEvent && ev.nativeEvent.inputType === 'deleteContentBackward') {
      formik.handleChange(ev);
    } else {
      const cnpjInputLegth = ev.target.value.length;
      if (cnpjInputLegth === 2) {
        ev.target.value += '.';
      } else if (cnpjInputLegth === 6) {
        ev.target.value += '.';
      } else if (cnpjInputLegth === 10) {
        ev.target.value += '/';
      } else if (cnpjInputLegth === 15) {
        ev.target.value += '-';
      }
      formik.handleChange(ev);
    }
  }

  useEffect(() => {
    async function findInstitutionReceipt() {
      const { error, payload } = await FindInstitutionReceiptUseCase(selectedACL!.id);

      if (error) {
        return setAlert(error, 'error');
      }

      const receiptConfigDataExist = payload?.data;
      if (receiptConfigDataExist) {
        setSignatureURL(payload.data._Signature.src);
        formik.setFieldValue('cnpj', payload?.data.cnpj);
        formik.setFieldValue('sender', payload?.data.sender);
        formik.setFieldValue('name', payload?.data.name);
        formik.setFieldValue('address.zipCode', payload?.data.address.zipCode);
        formik.setFieldValue('address.number', payload?.data.address.number);
        formik.setFieldValue('address.neighborhood', payload?.data.address.neighborhood);
        formik.setFieldValue('address.city', payload?.data.address.city);
        formik.setFieldValue('address.city', payload?.data.address.city);
        formik.setFieldValue('address.complement', payload?.data.address.complement);
        formik.setFieldValue('address.state', payload?.data.address.state);
      }
    }
    findInstitutionReceipt();
  }, []);

  useEffect(() => {
    function cleanAddressInputField() {
      formik.setFieldValue('address.city', '');
      formik.setFieldValue('address.address', '');
      formik.setFieldValue('address.state', '');
    }

    function touchInputsForRunValidation() {
      formik.setFieldTouched('address.city', true);
      formik.setFieldTouched('address.address', true);
      formik.setFieldTouched('address.state', true);
    }

    async function getAddressMetadataUsingZipCode() {
      const zipCodeNumericString = formik.values.address.zipCode.replace(/[^0-9]/g, '');
      const zipCodeIsValidToPerformAnApiCall = zipCodeNumericString.length === 8;

      if (zipCodeIsValidToPerformAnApiCall) {
        const result = await fetchAddressFromCep(zipCodeNumericString);

        if (!result.error) {
          formik.setFieldValue('address.road', result.data.logradouro, true);
          formik.setFieldValue('address.neighborhood', result.data.bairro, true);
          formik.setFieldValue('address.city', result.data.localidade, true);
          formik.setFieldValue('address.state', result.data.uf, true);

          /* This setTimeout is used to validation process works
          
            see more: https://github.com/jaredpalmer/formik/issues/2059
          */
          setTimeout(() => touchInputsForRunValidation());
        } else {
          cleanAddressInputField();
        }
      }
    }
    getAddressMetadataUsingZipCode();
  }, [formik.values.address.zipCode]);

  return (
    <Box padding="100px 25px" width="100%">
      <Title>Recibo</Title>
      <Container.main>
        <form
          style={{ display: 'flex', flexFlow: 'column', gap: '1rem' }}
          onSubmit={formik.handleSubmit}
        >
          <LabelInputFormCampaign
            error={formik.errors.name && formik.touched.name ? formik.errors.name : undefined}
            htmlFor="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            aria-labelledby=""
            type="text"
            id="name"
            name="name"
          >
            Nome do representande legal *
          </LabelInputFormCampaign>
          <LabelInputFormCampaign
            error={formik.errors.cnpj && formik.touched.cnpj ? formik.errors.cnpj : undefined}
            htmlFor="cnpj"
            value={formik.values.cnpj}
            onBlur={formik.handleBlur}
            onChange={applyMaskOnCNPJ}
            aria-labelledby=""
            type="text"
            id="cnpj"
            name="cnpj"
          >
            CNPJ *
          </LabelInputFormCampaign>
          <LabelInputFormCampaign
            error={formik.errors.sender && formik.touched.sender ? formik.errors.sender : undefined}
            htmlFor="sender"
            value={formik.values.sender}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            aria-labelledby=""
            type="text"
            id="sender"
            name="sender"
          >
            Nome da instituicao emissora *
          </LabelInputFormCampaign>

          <Container.address>
            <LabelInputFormCampaign
              error={
                formik.errors.address?.zipCode && formik.touched.address?.zipCode
                  ? formik.errors.address?.zipCode
                  : undefined
              }
              htmlFor="address.zipCode"
              value={formik.values.address.zipCode}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              aria-labelledby=""
              type="text"
              id="address.zipCode"
              name="address.zipCode"
            >
              CEP *
            </LabelInputFormCampaign>

            <LabelInputFormCampaign
              error={
                formik.errors.address?.road && formik.touched.address?.road
                  ? formik.errors.address?.road
                  : undefined
              }
              htmlFor="address.road"
              value={formik.values.address.road}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              aria-labelledby=""
              type="text"
              id="address.road"
              name="address.road"
            >
              Rua *
            </LabelInputFormCampaign>
            <LabelInputFormCampaign
              error={
                formik.errors.address?.neighborhood && formik.touched.address?.neighborhood
                  ? formik.errors.address?.neighborhood
                  : undefined
              }
              htmlFor="address.neighborhood"
              value={formik.values.address.neighborhood}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              aria-labelledby=""
              type="text"
              id="address.neighborhood"
              name="address.neighborhood"
            >
              Bairro *
            </LabelInputFormCampaign>
            <LabelInputFormCampaign
              error={
                formik.errors.address?.city && formik.touched.address?.city
                  ? formik.errors.address?.city
                  : undefined
              }
              htmlFor="address.city"
              value={formik.values.address.city}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              aria-labelledby=""
              type="text"
              id="address.city"
              name="address.city"
            >
              Cidade *
            </LabelInputFormCampaign>
            <LabelInputFormCampaign
              error={
                formik.errors.address?.number && formik.touched.address?.number
                  ? formik.errors.address?.number
                  : undefined
              }
              htmlFor="address.number"
              value={formik.values.address.number}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              aria-labelledby=""
              type="text"
              id="address.number"
              name="address.number"
            >
              Numero *
            </LabelInputFormCampaign>

            <SelectInput
              error={
                formik.errors.address?.state && formik.touched.address?.state
                  ? formik.errors.address?.state
                  : undefined
              }
              options={selectOptionsState}
              htmlFor="address.state"
              value={formik.values.address.state}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              aria-labelledby=""
              id="address.state"
              name="address.state"
            >
              Estado *
            </SelectInput>

            <LabelInputFormCampaign
              error={
                formik.errors.address?.complement && formik.touched.address?.complement
                  ? formik.errors.address?.complement
                  : undefined
              }
              aria-labelledby=""
              type="text"
              htmlFor="address.complement"
              value={formik.values.address.complement}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              id="address.complement"
              name="address.complement"
            >
              Complemento
            </LabelInputFormCampaign>
          </Container.address>
          <BlueButton loading={false} type="submit">
            Salvar
          </BlueButton>
        </form>

        <Box marginTop="1rem" gap="1rem" display="flex" flexDirection="column">
          <Component.signature src={signatureURL!} alt="Assinatura nao encontrada" />
          <input
            onChange={(ev) => sendNewImageUseCase(ev, selectedACL!.id)}
            type="file"
            accept=".png"
          />
        </Box>
      </Container.main>
    </Box>
  );
};
