import styled from 'styled-components';

export const Container__Main = styled.div`
  background-color: ${(p) => p.theme.colors.background};
  padding: 1rem;
`;

export const Container__Address = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;

  @media screen and (max-width: ${(p) => p.theme.media.mobileSmall}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Component__Signature = styled.img`
  width: 100%;
  max-width: 350px;
  border: 2px solid ${(p) => p.theme.colors.blue};
`;

export const Container = {
  main: Container__Main,
  address: Container__Address,
};

export const Component = {
  signature: Component__Signature,
};
