import { useState } from 'react';
import { Container, Container__Loading } from './styles';
import SucessPay from '../SucessPay';
import error from '../../../../../../../../../assets/icons/error.svg';
import { SmallBlueButton } from '../../../../../../../Button';
import processing from '../../../../../../../../../assets/icons/processing.svg';
import {
  GetStatusOfLastPaymentOrderOfUser,
  OrderStatusEnum,
} from '../../../../../../../../../../application/useCases/donation/GetStatusOfLastPaymentOrderOfUser';
import { useAlert } from '../../../../../../../../../../infra/config/AlertContext/useAlert';
import { usePaymentStore } from '../../../../../../../../../../infra/state/usePaymentStore';
import { Util } from '../../../../../../../../../../application/utils';

interface Props {
  onClose: () => void;
  campaignId: number | null;
}

export default function LoadingPay({ onClose, campaignId }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [paymentOrderStatus, setPaymentOrderStatus] = useState<OrderStatusEnum | null>(null);
  const [isClosed, setIsClosed] = useState(false);
  const { setAlert } = useAlert();

  const {
    state: { paymentUrl, preOrderId },
  } = usePaymentStore();

  function handleClick(ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    ev.preventDefault();
    Util.openNewWindowTabWithPaymentUrl(paymentUrl);
  }

  const handleButtonClick = async () => {
    setIsLoading(true);
    const { error, payload } = await GetStatusOfLastPaymentOrderOfUser(preOrderId);
    setIsLoading(false);

    if (error || !payload) {
      return setAlert(error!, 'error');
    }

    if (payload.data.status === OrderStatusEnum.SUCESSO) {
      setPaymentOrderStatus(payload.data.status);
    } else if (payload.data.status === OrderStatusEnum.PENDENTE) {
      if (!isClosed) {
        setPaymentOrderStatus(OrderStatusEnum.PENDENTE);
      } else {
        onClose();
      }
      setIsClosed(true);
    } else if (payload.data.status === OrderStatusEnum.CANCELADO) {
      if (!isClosed) {
        setPaymentOrderStatus(OrderStatusEnum.CANCELADO);
      } else {
        onClose();
      }
      setIsClosed(true);
    }
  };

  if (!paymentOrderStatus)
    return (
      <Container.box>
        <img src={processing} alt="processing" />
        <Container.title>Aguarde!</Container.title>
        <Container.text>
          Estamos processando a sua transação em uma nova janela.{' '}
        </Container.text>{' '}
        <Container.text style={{ fontWeight: 700 }}>
          Por favor, verifique se os pop-ups estão desbloqueados.
        </Container.text>{' '}
        <Container.text>
          Recomendamos a utilização do Google Chrome para uma melhor experiência nesta operação.
        </Container.text>
        <Container.text>
          Caso não consiga acessar,{' '}
          <a
            onClick={handleClick}
            href={window.localStorage.getItem('checkout')!}
            style={{ fontWeight: 700 }}
          >
            clique aqui
          </a>
        </Container.text>
        <SmallBlueButton onClick={handleButtonClick} loading={isLoading}>
          Verificar Transação
        </SmallBlueButton>
      </Container.box>
    );

  if (paymentOrderStatus === OrderStatusEnum.CANCELADO)
    return (
      <Container.box>
        <img src={error} alt="error" />
        <Container.title>Ops... Ocorreu um erro!</Container.title>
        <Container.text>Ocorreu um erro inesperado.</Container.text>
        <SmallBlueButton onClick={handleButtonClick} loading={isLoading}>
          Tentar Novamente
        </SmallBlueButton>
      </Container.box>
    );

  if (paymentOrderStatus === OrderStatusEnum.SUCESSO)
    return (
      <Container.box>
        <SucessPay onClose={onClose} campaignId={campaignId} />{' '}
      </Container.box>
    );

  return (
    <Container.box>
      <Container__Loading />
      <Container.title>Pendente!</Container.title>
      <Container.text>Aguardando o processamento.</Container.text>
      <SmallBlueButton onClick={handleButtonClick} loading={isLoading}>
        Tentar Novamente
      </SmallBlueButton>
    </Container.box>
  );
}
